export default function buildSearchParams(search) {
  const params = {};

  for (const key in search) {
    if (key === "trigger") {
      continue;
    } else if (typeof search[key] === "boolean") {
      params[key] = search[key] ? "1" : "0";
    } else if (
      search[key] !== null &&
      search[key].constructor.name === "Object"
    ) {
      if (JSON.stringify(search[key]) !== "{}") {
        params[key] = {};
        for (const index in search[key]) {
          if (search[key][index] !== "") {
            params[key][index] = search[key][index];
          }
        }
      }
    } else if (search[key]) {
      params[key] = search[key];
    }
  }

  return params;
}
