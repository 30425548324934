import { buildSearchParams } from "@/core/helpers";
import { debounce } from "@/core/plugins/debounce";

export default {
  computed: {
    headersShown() {
      const headers = [];

      for (const index in this.headers) {
        if (
          this.headers[index].hide === undefined ||
          !this.headers[index].hide()
        ) {
          headers.push(this.headers[index]);
        }
      }

      return headers;
    },
  },
  data() {
    return {
      search: "",
      total: null,
      list: [],
      loading: false,
      pagination: {
        page: 1,
        rowsPerPage: 20,
      },
      itemsPerPage: 50,
      page: 1,
      selected: [],
      dataTableAttrs: {
        fixedHeader: true,
        height: "73vh",
        loadingText: "Yükleniyor",
        footerProps: {
          itemsPerPageOptions: [50, 100, 200, 300, 500],
          disableItemsPerPage: false,
          showFirstLastPage: true,
        },
        showSelect: true,
        itemKey: "id",
        dense: true,
      },
    };
  },
  methods: {
    buildSearchParams,
    debounce,
    handleError(error) {
      // eslint-disable-next-line
            console.log(error);
      if (error.response.status === 401) {
        this.$toast.error("Kullanıcı girişi yapmalısınız.");
      } else if (error.response.status === 403) {
        this.$toast.error("Bunu yapmaya yetkili değilsiniz.");
      } else if (error.response.status === 422) {
        for (const itemKey in error.response.data.errors) {
          const messageList = error.response.data.errors[itemKey];
          if (messageList.constructor.name === "String") {
            this.$toast.error(messageList);
          } else if (messageList.constructor.name === "Array") {
            for (const index in messageList) {
              this.$toast.error(messageList[index]);
            }
          }
        }
      } else if (error.response.status === 500) {
        this.$toast.error("Sunucu hatası. İşleminiz kaydedilmedi.");
      } else if (error.response.status === 503) {
        this.$toast.error(
          "Sunucu bakım modunda. Lütfen daha sonra tekrar deneyin."
        );
      } else if (error.response.status === 404) {
        this.$toast.error("Öğe bulunamadı.");
      }
    },
    resetTable() {
      // this.list = [];
      this.selected = [];
    },
  },
};
