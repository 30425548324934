<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <div
        class="d-flex flex-column justify-content-center"
        v-bind="attrs"
        v-on="on"
      >
        <slot name="button"></slot>
      </div>
    </template>
    <v-card>
      <v-card-title class="text-h5 primary text-white">
        <span>Filtrele</span>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-row>
          <v-col cols="12">
            <SMDatePicker
              :value="tempConditions.applicationDates"
              @update:value="tempConditions.applicationDates = $event"
              range
              multiple
            ></SMDatePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SMChannelSelectbox
              :value="tempConditions.channels"
              @update:value="tempConditions.channels = $event"
              multiple
            ></SMChannelSelectbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SMCampaignSelectbox
              :value="tempConditions.campaigns"
              @update:value="tempConditions.campaigns = $event"
              multiple
            ></SMCampaignSelectbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SMLeadStatusSelectbox
              :value="tempConditions.statuses"
              @update:value="tempConditions.statuses = $event"
              multiple
            ></SMLeadStatusSelectbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <SMAgentSelectbox
              :agent_id="tempConditions.agents"
              @update:agent_id="tempConditions.agents = $event"
              multiple
            ></SMAgentSelectbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="resetFilters"> Sıfırla </v-btn>
        <v-btn
          color="primary"
          @keypress.enter="applyFilters"
          :loading="loading"
          @click="applyFilters"
        >
          Filtrele
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SMAgentSelectbox from "../siliconmade/SM-Agent-Selectbox.vue";
import SMCampaignSelectbox from "../siliconmade/SM-Campaign-Selectbox.vue";
import SMChannelSelectbox from "../siliconmade/SM-Channel-Selectbox.vue";
import SMLeadStatusSelectbox from "../siliconmade/SM-Lead-Status-Selectbox.vue";
import SMDatePicker from "../siliconmade/SM-Date-Picker.vue";

export default {
  name: "FilterLeadList",
  components: {
    SMChannelSelectbox,
    SMCampaignSelectbox,
    SMLeadStatusSelectbox,
    SMAgentSelectbox,
    SMDatePicker,
  },
  props: {
    conditions: {
      type: Object,
      default: () => {
        return {
          agents: [],
          applicationDates: [],
          channels: [],
          statuses: [],
          campaigns: [],
        };
      },
      resetFilters: {
        type: Function,
        required: true,
      },
    },
  },
  data() {
    return {
      dialog: false,
      tempConditions: Object.assign({}, this.conditions),
      menu: false,
      loader: null,
      loading: false,
    };
  },
  methods: {
    resetFilters() {
      this.tempConditions = {
        agents: [],
        channels: [],
        statuses: [],
        applicationDates: [],
        campaigns: [],
      };
      this.$emit("reset:conditions", this.tempConditions);
    },
    applyFilters() {
      this.loader = "loading";
      this.$emit("apply:conditions", Object.assign({}, this.tempConditions));
      this.dialog = false;
      this[this.loader] = false;
      this.loader = null;
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
  },
};
</script>
