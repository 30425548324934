<template>
  <v-card>
    <v-toolbar color="primary" class="text-white rounded-0" height="80px">
      <v-toolbar-title class="ml-3">
        <h2>Lead Listesi</h2>
      </v-toolbar-title>
      <v-spacer />
      <div class="d-flex align-items-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              @click="agents = !agents"
              color="cursor-pointer"
              v-role="{
                roles: ['lead_manager', 'superadmin', 'siliconmade_admin'],
              }"
              class="mr-5"
              dark
              v-on="on"
            >
              mdi-account-multiple-outline
            </v-icon>
          </template>
          <span>Agent Listesi Goruntule</span>
        </v-tooltip>
        <v-dialog v-model="lejant" scrollable max-width="400px">
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  color="cursor-pointer"
                  class="mr-5"
                  dark
                  v-on="{
                    ...tooltip,
                    ...dialog,
                  }"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Lejant Goruntule</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-toolbar color="primary" class="text-white">
              <v-toolbar-title>Lejant Goruntule</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="lejant = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="pa-5">
              <div class="d-flex justify-content-start align-items-center">
                <div class="non-user-badge"></div>
                <b class="ml-1 mr-4">Kullanıcı Eşletirmesi Bekleyen Lead</b>
                <div class="non-agent-badge"></div>
                <b class="ml-1">Agent Atanması Bekleyen Lead</b>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <LogModal log_name="lead">
          <template v-slot:button>
            <v-icon dark color="cursor-pointer" class="mr-5">
              mdi-history
            </v-icon>
          </template>
        </LogModal>

        <v-icon
          color="error cursor-pointer"
          class="mr-5"
          v-permission="{
            permissions: ['leads-delete'],
          }"
          dark
          :disabled="disableDeleteButton"
          @click="showDeleteLeadModal"
        >
          mdi-delete
        </v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              color="cursor-pointer"
              v-bind="attrs"
              @click="() => loadList()"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          dark
          single-line
          append-icon="search"
        ></v-text-field>
        <router-link :to="{ name: 'lead-create', query: { editable: true } }">
          <v-btn
            v-permission="{
              permissions: ['leads-create'],
            }"
            elevation="0"
            color="success"
          >
            <v-icon small>mdi-plus</v-icon>
            Yeni Lead
          </v-btn>
        </router-link>
      </div>
      <KTTopMenu />
    </v-toolbar>
    <v-app-bar
      v-if="agents"
      color="indigo"
      elevation="0"
      dark
      v-role="{
        roles: ['lead_manager', 'superadmin', 'siliconmade_admin'],
      }"
      height="auto"
    >
      <v-app-bar-title class="ml-3">
        <h4>Agentlar</h4>
      </v-app-bar-title>
      <v-spacer> </v-spacer>
      <v-row v-if="!loading" justify="end">
        <div class="mr-7" v-for="agent in Agents" :key="agent.id">
          <span> {{ agent?.name }}: {{ getAgentLeadsCount(agent.id) }} </span>
        </div>
      </v-row>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
        :size="10"
        width="7"
      ></v-progress-circular>
    </v-app-bar>
    <v-card-text>
      <v-data-table
        :fixed-header="true"
        id="LeadListTable"
        v-bind="dataTableAttrs"
        v-model="selected"
        :headers="headers"
        :items="filteredLeadListByConditions"
        :item-class="changeRowBGColor"
        :loading="loading"
        :header-props="{ sortIcon: null }"
        :server-items-length="total"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        disable-sort
        :auto-width="true"
        show-select
        dense
        item-key="id"
      >
        <template #top>
          <div class="d-flex">
            <FilterLeadList
              ref="filterLeadList"
              :conditions="conditions"
              @apply:conditions="applyConditions"
              @reset:conditions="resetConditions"
              :resetFilters="resetFilters"
            >
              <template v-slot:button>
                <v-btn small
                  ><v-icon small v-on="on">mdi-filter</v-icon>
                  Filtrele
                </v-btn>
              </template>
            </FilterLeadList>
            <templete>
              <div class="d-flex">
                <v-btn small @click="resetFilters">
                  <v-icon small v-on="on">mdi-trash-can</v-icon>
                  Filtreleri Temizle
                </v-btn>
              </div>
            </templete>
            <templete>
              <div class="d-flex">
                <v-btn small @click="toggleClosedLeadsVisibility">
                  <v-icon small v-on="on" v-bind="attrs">
                    {{ closedLeadsVisible ? "mdi-eye-off" : "mdi-eye" }}
                  </v-icon>
                  {{
                    closedLeadsVisible
                      ? "Kapanan Lead'leri Gizle"
                      : "Kapanan Lead'leri Göster"
                  }}
                </v-btn>
              </div>
            </templete>
            <v-chip-group
              v-for="(condition, index) in notEmptyConditions"
              :key="index"
              class="ml-3"
            >
              <v-chip
                v-for="(item, i) in condition"
                :key="i"
                label
                :color="conditionColors[index]"
                small
                close
                @click:close="condition.splice(i, 1)"
              >
                <span v-if="item.name">{{ item.name }}</span>
                <span v-else> {{ item }} </span>
              </v-chip>
            </v-chip-group>
          </div>
        </template>
        <template #item.application_date="{ item }">
          {{ item.application_date | formatDate }}
        </template>
        <template #item.phone="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span
                @mouseover="changeCursor"
                @mouseleave="resetCursor"
                @click="copyPhone(item.phone)"
                v-on="on"
              >
                {{ formatPhoneInput(item.phone) }}
              </span>
            </template>
            <span>Kopyala</span>
          </v-tooltip>
        </template>
        <template #item.channel_id="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">
                {{ item.channel?.icon }}
              </v-icon>
            </template>
            <span>{{ item.channel?.name }}</span>
          </v-tooltip>
        </template>
        <template #item.campaign_id="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.campaign?.code }}</span>
            </template>
            <span>{{ item.campaign?.name }}</span>
          </v-tooltip>
        </template>
        <template #item.first_name="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{
                  name: 'lead-show',
                  params: { id: item.id },
                  query: { editable: false },
                }"
              >
                <span v-on="on" v-bind="attrs">{{ item.first_name }}</span>
              </router-link>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template #item.agent_id="{ item }">
          <span>
            {{ item.agent_id ? item?.agent?.name : "Atanmamış" }}
          </span>
        </template>
        <template #item.status="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ loading ? "" : getLeadStatusTranslate(item.status) }}
              </span>
            </template>
            <div v-if="item.lead_result">
              <span
                >Sonuç Kodu:
                {{
                  getLeadResultCodeTranslate(item.lead_result?.result_code_id)
                }}</span
              >
              <br />
              <span
                >Alt Sonuç Kodu:
                {{
                  getLeadResultCodeTranslate(
                    item.lead_result?.sub_result_code_id
                  )
                }}</span
              >
            </div>
          </v-tooltip>
        </template>
        <template #item.actions="{ item }">
          <SMAgentActionButtons
            :selectedLeads="selected"
            :lead="item"
            @update:selectedLeads="selected = $event"
            @leadListUpdated="loadList"
          />
        </template>
        <template #item.user="{ item }">
          <v-tooltip bottom v-if="item.user_id">
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{ name: 'user-edit', params: { id: item.user_id } }"
              >
                <v-icon color="primary" small v-bind="attrs" v-on="on">
                  mdi-account
                </v-icon>
              </router-link>
            </template>
            <span>Kullanıcıya Git</span>
          </v-tooltip>
        </template>
        <template #item.edit="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{
                  name: 'lead-edit',
                  params: { id: item.id },
                  query: { editable: true },
                }"
              >
                <v-icon
                  color="warning"
                  small
                  v-permission="{
                    permissions: ['leads-update'],
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import KTTopMenu from "@/view/layout/header/TopMenu.vue";

import { mapActions, mapGetters } from "vuex";
import { DELETE_LEAD } from "@/core/services/store/leads.module";

import { formatPhoneInput } from "@/core/helper.js";

import LogModal from "@/view/content/modals/LogModal.vue";
import SMAgentActionButtons from "../../content/siliconmade/SM-Agent-Action-Buttons.vue";
import FilterLeadList from "../../content/components/FilterLeadList.vue";
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  name: "LeadList",
  components: { LogModal, KTTopMenu, SMAgentActionButtons, FilterLeadList },
  data() {
    return {
      closedLeadsVisible: false,
      lejant: false,
      agents: false,
      search: "",
      conditions: {
        agents: [],
        channels: [],
        statuses: [],
        campaigns: [],
        applicationDates: [],
      },
      conditionColors: [
        "orange lighten-4",
        "blue lighten-4",
        "red lighten-4",
        "green lighten-4",
        "yellow lighten-4",
      ],
    };
  },
  async created() {
    await Promise.all([
      this.getAgents(),
      this.getLeadStatuses(),
      this.fetchResultCodes(),
      await this.loadList(),
    ]);
    this.loading = false;
  },

  methods: {
    ...mapActions([DELETE_LEAD, "fetchResultCodes"]),
    formatPhoneInput,

    changeItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.page = 1;
      this.loadList();
    },
    changePage(page) {
      this.page = page;
      this.loadList();
    },
    loadList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.list = [];
      const params = {
        itemsPerPage: this.itemsPerPage,
        page: this.page,
        search: this.search,
        channelIds: this.conditions.channels.map((channel) => channel.id),
        campaignIds: this.conditions.campaigns.map((campaign) => campaign.id),
        statusIds: this.conditions.statuses.map((status) => status.id),
        agentIds: this.conditions.agents.map((agent) => agent.id),
        applicationStartDate:
          this.conditions.applicationDates.length > 0
            ? this.conditions.applicationDates[0]
                .split(" - ")[0]
                .replaceAll("/", "-")
            : null,
        applicationEndDate:
          this.conditions.applicationDates.length > 0
            ? this.conditions.applicationDates[0]
                .split(" - ")[1]
                .replaceAll("/", "-")
            : null,
      };

      this.$api
        .query("leads/paginated", { params })
        .then((response) => {
          this.list = response.data.leads;
          this.total = response.data.total;
          this.itemsPerPage = response.data.itemsPerPage;
          this.page = response.data.page;
        })
        .catch(this.handleError)
        .finally(() => (this.loading = false));
    },

    async acceptDelete() {
      try {
        await this.removeLead(this.selected[0]);
        this.$toast.success("Lead silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selected = [];
        await this.loadList();
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
    toggleClosedLeadsVisibility() {
      this.closedLeadsVisible = !this.closedLeadsVisible;
    },
    showDeleteLeadModal() {
      const params = {
        title: "Seçtiğiniz lead silinecek",
        text: "Seçili leadi silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptDelete();
        },
      };
      this.$approvalModal.show(params);
    },
    changeRowBGColor(item) {
      if (item.user && item.user.lead_count > 1) return "blue lighten-4";
      if (item.user_id === null) return "blue-grey lighten-4";
      else if (item.agent_id === null) return "red lighten-4";
      else return "";
    },
    copyPhone(phoneNumber) {
      let formattedPhoneNumber = phoneNumber.replace(/\D/g, "");
      navigator.clipboard.writeText(formattedPhoneNumber);
      this.$toast.info("Telefon numarası kopyalandı", {
        position: "bottom-right",
        duration: 1000,
      });
    },
    changeCursor() {
      document.querySelector("div").classList.add("cursor-pointer");
    },
    resetCursor() {
      document.querySelector("div").classList.remove("cursor-pointer");
    },
    getAgentLeadsCount(agent_id) {
      return this.leads.filter((lead) => lead.agent_id === agent_id).length;
    },
    getLeadStatusTranslate(status) {
      const translate = this.LeadStatus.find(
        (s) => s.value === status
      ).translate;
      switch (translate) {
        case "Teklif Taslağı Oluşturuldu":
          return "T.T.O.";
        case "Kullanıcı Kaydı Tamamlandı":
          return "K.K.T.";
        case "E-Posta Doğrulanması Bekleniyor":
          return "E.P.D.B.";
        default:
          return translate;
      }
    },

    getLeadResultCodeTranslate(resultCodeId) {
      return this.resultCodes.find((code) => code.id === resultCodeId)?.name;
    },
    getLeadStatuses() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get("enums", {
            params: { enum: "lead_status_enum" },
          })
          .then(
            ({ data }) => (
              resolve(data),
              localStorage.setItem("lead_statuses", JSON.stringify(data))
            )
          )
          .catch((error) => reject(error));
      });
    },
    getAgents() {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get("roles/agents")
          .then(({ data }) => {
            const mappedAgents = data.map((agent) => ({
              id: agent.id,
              name: agent.name,
            }));
            resolve(mappedAgents);
            localStorage.setItem("agents", JSON.stringify(mappedAgents));
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    applyConditions(newConditions) {
      this.conditions = newConditions;
      this.loadList();
    },
    resetConditions() {
      this.conditions = {
        agents: [],
        channels: [],
        statuses: [],
        campaigns: [],
        applicationDates: [],
      };
      this.search = "";
      this.$emit("reset:conditions", this.conditions);
      //this.loadList();
    },
    resetFilters() {
      this.$refs.filterLeadList.resetFilters();
    },
  },
  watch: {
    search: debounce(function () {
      this.itemsPerPage = 50;
      this.page = 1;
      this.loadList();
    }, 250),
    conditions: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "";
      const [datePart, timePart] = value.split(" ");
      const [day, month, year] = datePart.split("-");
      const [hour, minute] = timePart.split(":");
      const date = new Date(year, month - 1, day, hour, minute);
      return (
        date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
        " " +
        date.toLocaleDateString("tr-TR", { day: "2-digit", month: "2-digit" })
      );
    },
  },
  computed: {
    ...mapGetters([
      "leads",
      "notes",
      "getCurrentUserRoles",
      "currentUser",
      "getCurrentUserRole",
      "resultCodes",
    ]),
    selectedLeadCount() {
      return this.selected.length;
    },
    Agents() {
      return JSON.parse(localStorage.getItem("agents")) || [];
    },
    LeadStatus() {
      return JSON.parse(localStorage.getItem("lead_statuses")) || [];
    },
    disableDeleteButton() {
      if (this.selected?.length === 0 || this.selected?.length > 1) return true;
      else if (this.selected[0]?.status === 10) return true;
      else return false;
    },

    headers() {
      const tableHeaders = [
        {
          text: "Başvuru Tarihi",
          value: "application_date",
          align: "left",
        },
        { text: "Kanal", value: "channel_id", align: "left" },
        { text: "Kampanya", value: "campaign_id", align: "left" },
        { text: "İsim", value: "first_name", align: "left" },
        { text: "Telefon", value: "phone", align: "left" },
        { text: "Durum", value: "status", align: "left" },
        {
          text: "Aksiyonlar",
          value: "actions",
          sortable: false,
          align: "left",
          width: "38%",
        },
        { text: "", value: "user", align: "left" },
        { text: "", value: "edit", align: "left" },
        { text: "", value: "data-table-select", align: "right" },
      ];
      if (
        this.getCurrentUserRoles.includes("lead_manager") ||
        this.getCurrentUserRoles.includes("siliconmade_admin") ||
        this.getCurrentUserRoles.includes("superadmin")
      ) {
        tableHeaders.splice(6, 0, {
          text: "Agent",
          value: "agent_id",
          align: "left",
        });
      }
      return tableHeaders;
    },
    notEmptyConditions() {
      return Object.values(this.conditions).filter(
        (condition) => condition.length > 0
      );
    },
    filteredLeadListByConditions() {
      let filteredLeads = this.list;
      if (!this.closedLeadsVisible) {
        filteredLeads = filteredLeads.filter(
          (lead) => ![9, 10, 12].includes(lead.status)
        );
      }
      return filteredLeads;
    },
  },
  mounted() {
    this.loadList();
  },
};
</script>

<style lang="scss">
$data-table-dense-row-height: 15px;

.v-data-table {
  .v-data-table__wrapper {
    table {
      thead {
        tr {
          height: $data-table-dense-row-height;
          th {
            padding: 0px 2px;
          }
        }
      }
      tbody {
        tr {
          height: $data-table-dense-row-height;
          td {
            padding: 0px;
            vertical-align: center;
            align-content: center;
            height: 15px;
          }
        }
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.non-user-badge {
  background-color: #cfd8dc;
  padding: 2px 4px;
  border-radius: 10px;
  border: 0.5px solid #1a1b1b;
  height: 15px;
  width: 35px;
}

.non-agent-badge {
  background-color: #ffcdd2;
  padding: 2px 4px;
  border-radius: 10px;
  border: 0.5px solid #1a1b1b;
  height: 15px;
  width: 35px;
}

table {
  border-collapse: collapse;
}

td {
  padding: 0;
  margin: 0;
}

#LeadListTable {
  table tr > td {
    border-left: 1px solid #dddddd;
  }
  table tr > td:nth-child(8),
  table tr > td:nth-child(9) {
    border-left: none;
  }
}

#kt_wrapper,
#kt_content .v-card__text {
  padding-bottom: 0 !important;
}
.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-text-field.v-text-field--is-booted.v-select {
  margin: 0 !important;
}
</style>
