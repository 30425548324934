<template>
  <v-select
    :items="cachedLeadStatus"
    item-value="id"
    item-text="name"
    v-model="selectedStatus"
    hide-details="auto"
    label="Durum"
    :multiple="multiple"
    @input="selectStatus"
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        close
        small
        @click:close="selectedStatus.splice(index, 1)"
        v-if="multiple"
      >
        <span>{{ item.name }}</span>
      </v-chip>
      <span v-else>{{ item.name }}</span>
    </template>
  </v-select>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SM-Lead-Status-Selectbox",
  props: {
    value: Number,
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStatus: this.value,
    };
  },
  methods: {
    selectStatus() {
      if (this.multiple) {
        this.$emit(
          "update:value",
          this.cachedLeadStatus.filter((status) =>
            this.selectedStatus.includes(status.id)
          )
        );
        return;
      }
      this.$emit("update:value", this.selectedStatus);
    },
  },
  computed: {
    cachedLeadStatus() {
      return JSON.parse(localStorage.getItem("lead_statuses")).map((c) => {
        return {
          id: c.value,
          name: c.translate,
        };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.multiple && this.selectedStatus != value) {
          this.selectedStatus = value;
          return;
        }
      },
    },
  },
});
</script>
