<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="selectedDate"
      transition="scale-transition"
      style="height: 25rem"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-combobox
          v-model="formattedDates"
          :multiple="multiple"
          label="Basvuru Tarih Aralığı"
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:selection="{ item }">
            <v-chip close small @click:close="selectedDate = []">
              <span>{{ item }}</span>
            </v-chip>
          </template>
        </v-combobox>
      </template>
      <v-date-picker v-model="selectedDate" :range="range" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn
          class="mt-1"
          small
          color="error"
          @click="
            () => {
              menu = false;
              selectedDate = [];
            }
          "
        >
          İptal
        </v-btn>
        <v-btn
          small
          class="mt-1"
          color="primary"
          @click="$refs.menu.save(selectedDate)"
        >
          Onayla
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { isEmptyObject } from "jquery";

export default {
  name: "SMDatePicker",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      dates: [],
      selectedDate: this.value,
    };
  },
  mounted() {
    if (this.range && this.value?.length === 1) {
      const temp = this.value[0];
      const parsedTemp = temp.split(" - ");
      this.selectedDate = parsedTemp;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      return this.$moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    formattedDates() {
      return this.selectedDate.map((date) => this.formatDate(date));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (this.multiple && isEmptyObject(value)) {
          this.selectedDate = value;
        }
      },
    },
    formattedDates() {
      if (this.selectedDate.length > 1 && this.range) {
        const formattedDates = this.selectedDate.reduce((acc, curr, idx) => {
          acc = this.formatDate(acc);
          curr = this.formatDate(curr);
          if (idx === 0) {
            acc = curr;
          } else {
            acc = `${acc} - ${curr}`;
          }
          return acc;
        });
        this.$emit("update:value", [formattedDates]);
      } else {
        this.$emit("update:value", this.selectedDate);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  height: 27rem !important;
}
.v-picker__body {
  height: 26rem !important;
}
</style>
